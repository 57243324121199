const actions = {
    FETCH_IT_COMPONENTS: 'FETCH_IT_COMPONENTS',
    FETCH_IT_COMPONENT: 'FETCH_IT_COMPONENT',
    RESET_IT_COMPONENT: 'RESET_IT_COMPONENT',

    fetchITComponentsAction: (data) => {
        return {
            type: actions.FETCH_IT_COMPONENTS,
            data: data
        };
    },

    resetITComponentFormAction: () => {
        return {
            type: actions.RESET_IT_COMPONENT,
            data: null
        };
    },

    fetchITComponentAction: (data) => {
        return {
            type: actions.FETCH_IT_COMPONENT,
            data: data
        };
    },
}

export default actions;
