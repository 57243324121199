const actions = {
    FETCH_ROSTERS: 'FETCH_ROSTERS',
    FETCH_ROSTER: 'FETCH_ROSTER',
    RESET_ROSTER: 'RESET_ROSTER',

    fetchRostersAction: (data) => {
        return {
            type: actions.FETCH_ROSTERS,
            data: data
        };
    },
    resetRosterFormAction: () => {
        return {
            type: actions.RESET_ROSTER,
            data: null
        };
    },
    fetchRosterAction: (data) => {
        return {
            type: actions.FETCH_ROSTER,
            data: data
        };
    },
};

export default actions;
