const actions = {
    FETCH_DAYSCHEDULES: 'FETCH_DAYSCHEDULES',
    FETCH_DAYSCHEDULE: 'FETCH_DAYSCHEDULE',
    RESET_DAYSCHEDULE: 'RESET_DAYSCHEDULE',

    fetchDaySchedulesAction: (data) => {
        return {
            type: actions.FETCH_DAYSCHEDULES,
            data: data
        };
    },
    resetDayScheduleFormAction: () => {
        return {
            type: actions.RESET_DAYSCHEDULE,
            data: null
        };
    },
    fetchDayScheduleAction: (data) => {
        return {
            type: actions.FETCH_DAYSCHEDULE,
            data: data
        };
    },
};

export default actions;
