import actions from "./actions";

const {FETCH_USER_PERMISSION, FETCH_USER_PERMISSIONS, RESET_USER_PERMISSION} = actions;

const initState = {
    userPermissions: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    userPermission: {_id: null}
};

const UserPermissionReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_PERMISSIONS:
            return {
                ...state,
                userPermissions: data
            };
        case RESET_USER_PERMISSION:
            return {
                ...state,
                userPermission: initState.userPermission,
            };
        case FETCH_USER_PERMISSION:
            return {
                ...state,
                userPermission: data,
            };
        default:
            return state;
    }
}

export default UserPermissionReducer;
