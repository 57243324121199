const actions = {
    FETCH_YEARLY_RESERVED_LEAVES: 'FETCH_YEARLY_RESERVED_LEAVES',
    FETCH_YEARLY_RESERVED_LEAVE: 'FETCH_YEARLY_RESERVED_LEAVE',
    RESET_YEARLY_RESERVED_LEAVE: 'RESET_YEARLY_RESERVED_LEAVE',

    fetchYearlyReservedLeavesAction: (data) => {
        return {
            type: actions.FETCH_YEARLY_RESERVED_LEAVES,
            data: data
        };
    },

    resetYearlyReservedLeaveFormAction: () => {
        return {
            type: actions.RESET_YEARLY_RESERVED_LEAVE,
            data: null
        };
    },

    fetchYearlyReservedLeaveAction: (data) => {
        return {
            type: actions.FETCH_YEARLY_RESERVED_LEAVE,
            data: data
        };
    },
};

export default actions;

