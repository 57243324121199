const actions = {
    FETCH_ATTENDANCE_CATEGORIES : 'FETCH_ATTENDANCE_CATEGORIES',
    FETCH_ATTENDANCE_CATEGORY  : 'FETCH_ATTENDANCE_CATEGORY',
    RESET_ATTENDANCE_CATEGORY  : 'RESET_ATTENDANCE_CATEGORY',

    fetchAttendanceCategoriesAction: (data) => {
        return {
            type: actions.FETCH_ATTENDANCE_CATEGORIES,
            data: data
        };
    },
    resetAttendanceCategoryFormAction: () => {
        return {
            type: actions.RESET_ATTENDANCE_CATEGORY,
            data: null
        };
    },
    fetchAttendanceCategoryAction: (data) => {
        return {
            type: actions.FETCH_ATTENDANCE_CATEGORY,
            data: data
        };
    },
}

export default actions;
