import actions from "./actions";

const { FETCH_DAYSCHEDULE, FETCH_DAYSCHEDULES, RESET_DAYSCHEDULE } = actions;

const initState = {
    daySchedules: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    daySchedule: { _id: null }
};

const DayScheduleReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_DAYSCHEDULES:
            return {
                ...state,
                daySchedules: data
            };
        case RESET_DAYSCHEDULE:
            return {
                ...state,
                daySchedule: initState.daySchedule,
            };
        case FETCH_DAYSCHEDULE:
            return {
                ...state,
                daySchedule: data,
            };
        default:
            return state;
    }
};

export default DayScheduleReducer;
