import actions from "./actions";

const {FETCH_USER_ATTENDANCE, FETCH_USER_ATTENDANCES, RESET_USER_ATTENDANCE} = actions;
const initState = {
    userAttendances: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    userAttendance: {_id: null}
};

const UserAttendanceReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_USER_ATTENDANCES:
            return {
                ...state,
                userAttendances: data
            }
        case RESET_USER_ATTENDANCE:
            return {
                ...state,
                userAttendance: initState.userAttendance,
            };
        case FETCH_USER_ATTENDANCE:
            return {
                ...state,
                userAttendance: data,
            };
        default:
            return state;
    }
}

export default UserAttendanceReducer;