import actions from "./actions";

const {FETCH_IT_COMPONENT, FETCH_IT_COMPONENTS, RESET_IT_COMPONENT} = actions;

const initState = {
    itComponents: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    itComponent: {_id: null}
};

const ITComponentReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_IT_COMPONENTS:
            return {
                ...state,
                itComponents: data
            }
        case RESET_IT_COMPONENT:
            return {
                ...state,
                itComponent: initState.itComponent,
            };
        case FETCH_IT_COMPONENT:
            return {
                ...state,
                itComponent: data,
            };
        default:
            return state;
    }
}

export default ITComponentReducer;
