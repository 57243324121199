const actions = {
    FETCH_LEAVES : 'FETCH_LEAVES',
    FETCH_LEAVE  : 'FETCH_LEAVE',
    RESET_LEAVE  : 'RESET_LEAVE',

    fetchLeavesAction: (data) => {
        return {
            type: actions.FETCH_LEAVES,
            data: data
        };
    },
    resetLeaveFormAction: () => {
        return {
            type: actions.RESET_LEAVE,
            data: null
        };
    },
    fetchLeaveAction: (data) => {
        return {
            type: actions.FETCH_LEAVE,
            data: data
        };
    },
}

export default actions;
