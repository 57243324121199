import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const productCategories = lazy(() => import('../../container/ITComponents/productCategories'));
const itComponents = lazy(() => import('../../container/ITComponents/components'));

const ITComponentRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/productCategories`} component={productCategories}/>
            <Route path={`${path}/itComponents`} component={itComponents}/>
        </Switch>
    );
};

export default ITComponentRoutes;
