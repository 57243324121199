import actions from "./actions";

const { FETCH_CLASS_MARKS_PERMISSIONS, RESET_CLASS_MARKS_PERMISSIONS } = actions;

const initState = {
    classMarksPermissions: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    }
};

const MarksInputPermissionReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_CLASS_MARKS_PERMISSIONS:
            return {
                ...state,
                classMarksPermissions: data
            };
        case RESET_CLASS_MARKS_PERMISSIONS:
            return {
                ...state,
                classMarksPermissions: initState.classMarksPermissions,
            };
        default:
            return state;
    }
};

export default MarksInputPermissionReducer;
