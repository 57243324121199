import actions from "./actions";

const { FETCH_PRODUCT_CATEGORY, FETCH_PRODUCT_CATEGORIES, RESET_PRODUCT_CATEGORY } = actions;

const initState = {
    productCategories: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    productCategory: { _id: null }
};

const ProductCategoryReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_PRODUCT_CATEGORIES:
            return {
                ...state,
                productCategories: data
            };
        case RESET_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: initState.productCategory,
            };
        case FETCH_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: data,
            };
        default:
            return state;
    }
}

export default ProductCategoryReducer;
