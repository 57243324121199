const actions = {
    FETCH_CLASS_MARKS_PERMISSIONS: 'FETCH_CLASS_MARKS_PERMISSIONS',
    RESET_CLASS_MARKS_PERMISSIONS: 'RESET_CLASS_MARKS_PERMISSIONS',

    fetchClassMarksPermissionsAction: (data) => {
        return {
            type: actions.FETCH_CLASS_MARKS_PERMISSIONS,
            data: data
        };
    },
    resetClassMarksPermissionsAction: () => {
        return {
            type: actions.RESET_CLASS_MARKS_PERMISSIONS,
            data: null
        };
    }
};

export default actions;
