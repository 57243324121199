import actions from "./actions";

const {FETCH_ATTENDANCE_CATEGORY, FETCH_ATTENDANCE_CATEGORIES, RESET_ATTENDANCE_CATEGORY} = actions;
const initState = {
    attendanceCategories: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    attendanceCategory: {_id: null}
};

const AttendanceCategoryReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ATTENDANCE_CATEGORIES:
            return {
                ...state,
                attendanceCategories: data
            }
        case RESET_ATTENDANCE_CATEGORY:
            return {
                ...state,
                attendanceCategory: initState.attendanceCategory,
            };
        case FETCH_ATTENDANCE_CATEGORY:
            return {
                ...state,
                attendanceCategory: data,
            };
        default:
            return state;
    }
}

export default AttendanceCategoryReducer;
