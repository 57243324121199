import actions from "./actions";

const {FETCH_SPECIALDAY, FETCH_SPECIALDAYS, RESET_SPECIALDAY} = actions;
const initState = {
    specialDays: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    specialDay: {_id: null}
};

const SpecialDayReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SPECIALDAYS:
            return {
                ...state,
                specialDays: data
            }
        case RESET_SPECIALDAY:
            return {
                ...state,
                specialDay: initState.specialDay,
            };
        case FETCH_SPECIALDAY:
            return {
                ...state,
                specialDay: data,
            };
        default:
            return state;
    }
}

export default SpecialDayReducer;