import actions from "./actions";

const {FETCH_ALL_STUDENTS, FETCH_MARKS_ENTRY_STUDENTS, FETCH_MY_STUDENTS, FETCH_SECTION_STUDENTS, FETCH_SUBJECT_STUDENTS} = actions;
const initState = {
    students: [],
    marksStudents: [],
    myStudents: [],
    sectionStudents: [],
    subjectStudents: {},
};

const AllStudentsReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ALL_STUDENTS:
            return {
                ...state,
                students: data
            }
        case FETCH_MY_STUDENTS:
            return {
                ...state,
                myStudents: data
            }
        case FETCH_SECTION_STUDENTS:
            return {
                ...state,
                sectionStudents: data
            }
        case FETCH_SUBJECT_STUDENTS:
            return {
                ...state,
                subjectStudents: data
            }
        case FETCH_MARKS_ENTRY_STUDENTS:
            return {
                ...state,
                marksStudents: data
            }
        default:
            return state;
    }
}

export default AllStudentsReducer;
