import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const MarksInputPermission = lazy(() => import('../../container/marksInputPermission/MarksInputAccess'));
const AllowUserMarksInput = lazy(() => import('../../container/marksInputPermission/allowUserMarksInput'));

const MarksInputPermissionRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/marksInputPermission`} component={MarksInputPermission}/>
            <Route path={`${path}/allowUserMarksInput`} component={AllowUserMarksInput}/>
        </Switch>
    );
};

export default MarksInputPermissionRoutes;