const actions = {
    FETCH_USER_MONTHLY_ATTENDANCES: 'FETCH_USER_MONTHLY_ATTENDANCES',

    fetchUserMonthlyAttendancesAction: (data) => {
        return {
            type: actions.FETCH_USER_MONTHLY_ATTENDANCES,
            data: data
        };
    },
};

export default actions;
