const actions = {
    FETCH_EARLY_LEAVES: 'FETCH_EARLY_LEAVES',
    FETCH_EARLY_LEAVE: 'FETCH_EARLY_LEAVE',
    RESET_EARLY_LEAVE: 'RESET_EARLY_LEAVE',

    fetchEarlyLeavesAction: (data) => {
        return {
            type: actions.FETCH_EARLY_LEAVES,
            data: data
        };
    },
    resetEarlyLeaveFormAction: () => {
        return {
            type: actions.RESET_EARLY_LEAVE,
            data: null
        };
    },
    fetchEarlyLeaveAction: (data) => {
        return {
            type: actions.FETCH_EARLY_LEAVE,
            data: data
        };
    },
};

export default actions;
