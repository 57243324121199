import actions from "./actions";

const {FETCH_USER_MONTHLY_ATTENDANCES } = actions;

const initState = {
    userMonthlyAttendances: {
        data: [],
    },
};

const UserMonthlyAttendanceReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_USER_MONTHLY_ATTENDANCES:
            return {
                ...state,
                userMonthlyAttendances: data
            };
        default:
            return state;
    }
};

export default UserMonthlyAttendanceReducer;
