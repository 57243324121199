import actions from "./actions";

const { FETCH_ROSTER, FETCH_ROSTERS, RESET_ROSTER } = actions;

const initState = {
    rosters: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    roster: { _id: null }
};

const RosterReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_ROSTERS:
            return {
                ...state,
                rosters: data
            };
        case RESET_ROSTER:
            return {
                ...state,
                roster: initState.roster,
            };
        case FETCH_ROSTER:
            return {
                ...state,
                roster: data,
            };
        default:
            return state;
    }
};

export default RosterReducer;
