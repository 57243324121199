const actions = {
    FETCH_PRODUCT_CATEGORIES: 'FETCH_PRODUCT_CATEGORIES',
    FETCH_PRODUCT_CATEGORY: 'FETCH_PRODUCT_CATEGORY',
    RESET_PRODUCT_CATEGORY: 'RESET_PRODUCT_CATEGORY',

    fetchProductCategoriesAction: (data) => {
        return {
            type: actions.FETCH_PRODUCT_CATEGORIES,
            data: data
        };
    },
    resetProductCategoryFormAction: () => {
        return {
            type: actions.RESET_PRODUCT_CATEGORY,
            data: null
        };
    },
    fetchProductCategoryAction: (data) => {
        return {
            type: actions.FETCH_PRODUCT_CATEGORY,
            data: data
        };
    },
}

export default actions;
