const actions = {
    FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
    FETCH_USER_PERMISSION: 'FETCH_USER_PERMISSION',
    RESET_USER_PERMISSION: 'RESET_USER_PERMISSION',

    fetchUserPermissionsAction: (data) => {
        return {
            type: actions.FETCH_USER_PERMISSIONS,
            data: data
        };
    },
    resetUserPermissionFormAction: () => {
        return {
            type: actions.RESET_USER_PERMISSION,
            data: null
        };
    },
    fetchUserPermissionAction: (data) => {
        return {
            type: actions.FETCH_USER_PERMISSION,
            data: data
        };
    },
};

export default actions;
