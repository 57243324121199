const actions = {
    FETCH_USER_ATTENDANCES : 'FETCH_USER_ATTENDANCES',
    FETCH_USER_ATTENDANCE  : 'FETCH_USER_ATTENDANCE',
    RESET_USER_ATTENDANCE  : 'RESET_USER_ATTENDANCE',

    fetchUserAttendancesAction: (data) => {
        return {
            type: actions.FETCH_USER_ATTENDANCES,
            data: data
        };
    },
    resetUserAttendanceFormAction: () => {
        return {
            type: actions.RESET_USER_ATTENDANCE,
            data: null
        };
    },
    fetchUserAttendanceAction: (data) => {
        return {
            type: actions.FETCH_USER_ATTENDANCE,
            data: data
        };
    },
}

export default actions;
