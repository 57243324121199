import actions from "./actions";

const {FETCH_MONTHLY_ATTENDANCES } = actions;

const initState = {
    monthlyAttendances: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
};

const MonthlyAttendanceReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_MONTHLY_ATTENDANCES:
            return {
                ...state,
                monthlyAttendances: data
            };
        default:
            return state;
    }
};

export default MonthlyAttendanceReducer;
