const actions = {
    FETCH_SPECIALDAYS : 'FETCH_SPECIALDAYS',
    FETCH_SPECIALDAY  : 'FETCH_SPECIALDAY',
    RESET_SPECIALDAY  : 'RESET_SPECIALDAY',

    fetchSpecialDaysAction: (data) => {
        return {
            type: actions.FETCH_SPECIALDAYS,
            data: data
        };
    },
    resetSpecialDayFormAction: () => {
        return {
            type: actions.RESET_SPECIALDAY,
            data: null
        };
    },
    fetchSpecialDayAction: (data) => {
        return {
            type: actions.FETCH_SPECIALDAY,
            data: data
        };
    },
}

export default actions;
