const actions = {
    FETCH_MONTHLY_ATTENDANCES: 'FETCH_MONTHLY_ATTENDANCES',

    fetchMonthlyAttendancesAction: (data) => {
        return {
            type: actions.FETCH_MONTHLY_ATTENDANCES,
            data: data
        };
    },
};

export default actions;
