import actions from "./actions";

const {FETCH_YEARLY_RESERVED_LEAVE, FETCH_YEARLY_RESERVED_LEAVES, RESET_YEARLY_RESERVED_LEAVE} = actions;

const initState = {
    yearlyReservedLeaves: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    yearlyReservedLeave: {_id: null}
};

const YearlyReservedLeaveReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_YEARLY_RESERVED_LEAVES:
            return {
                ...state,
                yearlyReservedLeaves: data
            };
        case RESET_YEARLY_RESERVED_LEAVE:
            return {
                ...state,
                yearlyReservedLeave: initState.yearlyReservedLeave,
            };
        case FETCH_YEARLY_RESERVED_LEAVE:
            return {
                ...state,
                yearlyReservedLeave: data,
            };
        default:
            return state;
    }
};

export default YearlyReservedLeaveReducer;