const actions = {
    FETCH_FOR_USERS : 'FETCH_FOR_USERS',
    FETCH_FOR_USER  : 'FETCH_FOR_USER',
    RESET_FOR_USER  : 'RESET_FOR_USER',

    fetchForOtherUsersAction: (data) => {
        return {
            type: actions.FETCH_FOR_USERS,
            data: data
        };
    },
    fetchUserForOtherAction: (data) => {
        return {
            type: actions.FETCH_FOR_USER,
            data: data
        };
    },
    resetForOtherUserFormAction: () => {
        return {
            type: actions.RESET_FOR_USER,
            data: null
        };
    },
}

export default actions;
