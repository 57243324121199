import actions from "./actions";

const { FETCH_EARLY_LEAVE, FETCH_EARLY_LEAVES, RESET_EARLY_LEAVE } = actions;

const initState = {
    earlyLeaves: {
        page: 0,
        perPage: 35,
        data: [],
        total: 0
    },
    earlyLeave: { _id: null }
};

const EarlyLeaveReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_EARLY_LEAVES:
            return {
                ...state,
                earlyLeaves: data
            };
        case RESET_EARLY_LEAVE:
            return {
                ...state,
                earlyLeave: initState.earlyLeave,
            };
        case FETCH_EARLY_LEAVE:
            return {
                ...state,
                earlyLeave: data,
            };
        default:
            return state;
    }
};

export default EarlyLeaveReducer;
